import { CodeItemType } from "../../../types/TestResult.type";
import { ResultMaskType } from "../../../types/ResultMask.type";
import { Icon } from "@iconify/react";
import { MaskItem } from "types/MaskItem";

type ResultMaskItemType = {
  name: string;
  grades: any;
  weights: any;
  resultSum: number;
};

const ResultCardMasks = ({
  codes,
  maskList,
  maskResult
}: {
  codes: CodeItemType[];
  maskList: ResultMaskType[];
  maskResult: MaskItem[];
}) => {
  // const maskResult = maskList.map((mask: ResultMaskType) =>
  //   getMaskResult(codes, mask)
  // ) as ResultMaskItemType[];

  // console.log(maskList, "maskList");

  return (
    <div className="mt-2">
      <div className="collapse collapse-arrow border border-gray-200">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Маски</div>
        <div className="collapse-content">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {maskResult.map((mask) => (
              <ResultMaskItem
                key={mask.name}
                resultSum={mask.resultSum}
                name={mask.name}
                grades={mask.grades}
                weights={mask.weights}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// const getMaskResult = (codes: CodeItemType[], mask: ResultMaskType) => {
//   const sortModeCode = mask.sortModeCode;

//   const codesRatingObject = Object.fromEntries(
//     codes.map((code) => [code.code, 0])
//   );

//   codes.forEach((codeItem) => {
//     let rating = 0;

//     // rating += codeItem.value / 75; // TODO 135 весы изменить

//     rating += codeItem.value / 135;

//     rating += codeItem.crucial / 4;

//     rating += codeItem.count / 8;

//     codesRatingObject[codeItem.code] = +rating.toFixed(3);
//   });

//   // console.log(codesRatingObject);

//   // const ratingToWeight = [
//   //   {
//   //     more: 0,
//   //     weight: 0.45,
//   //   },
//   //   {
//   //     more: 0.438,
//   //     weight: 0.97,
//   //   },
//   //   {
//   //     more: 0.875,
//   //     weight: 1.89,
//   //   },
//   //   {
//   //     more: 1.312,
//   //     weight: 3.29,
//   //   },
//   //   {
//   //     more: 1.75,
//   //     weight: 5.13,
//   //   },
//   //   {
//   //     more: 2.188,
//   //     weight: 7.17,
//   //   },
//   //   {
//   //     more: 2.625,
//   //     weight: 8.95,
//   //   },
//   //   {
//   //     more: 3.062,
//   //     weight: 10,
//   //   },
//   // ];
//   const ratingToWeight = [
//     {
//       more: 0,
//       weight: 0.45,
//       name: "очень слабый",
//       color: "",
//     },
//     {
//       more: 0.75,
//       weight: 0.97,
//       name: "слабый",
//       color: "",
//     },
//     {
//       more: 1.125,
//       weight: 1.89,
//       name: "средний",
//       color: "#ffc824",
//     },
//     {
//       more: 1.5,
//       weight: 3.29,
//       name: "выше среднего",
//       color: "#24ff3d",
//     },
//     {
//       more: 1.875,
//       weight: 5.13,
//       name: "сильный",
//       color: "#30a63e",
//     },
//     {
//       more: 2.25,
//       weight: 7.17,
//       name: "очень сильный",
//       color: "#63edf7",
//     },
//     {
//       more: 2.625,
//       weight: 8.95,
//       name: "подавляющий",
//       color: "#6868fc",
//     },
//     {
//       more: 3,
//       weight: 10,
//       name: "доминация",
//       color: "#2727f5",
//     },
//   ];

//   let codesWeightList = Object.entries(codesRatingObject).map(
//     ([code, rating]) => {
//       let weightResult = 0;

//       for (let i = 0; i < ratingToWeight.length; i++) {
//         const item = ratingToWeight[i];

//         let nextWeight = ratingToWeight[i + 1];

//         if (!nextWeight) {
//           weightResult = item.weight;
//           break;
//         }

//         if (rating >= item.more && rating < nextWeight.more) {
//           weightResult = item.weight;
//           break;
//         }
//       }

//       return { code, weight: weightResult };
//     }
//   );

//   const allWeight = codesWeightList.reduce((acc, item) => acc + item.weight, 0);

//   const weightListWithPercent = codesWeightList.map((c) => {
//     return {
//       ...c,
//       percent: +(c.weight / allWeight).toFixed(2),
//     };
//   });
//   weightListWithPercent.sort((a, b) => b.percent - a.percent);

//   ///////

//   const sortModeCodeExistMiddle = sortModeCode.length % 2 > 0;

//   let leftPartWeight: { code: string; weight: number }[] = sortModeCode
//     .slice(0, Math.floor(sortModeCode.length / 2))
//     .map((code) => {
//       let weight = codesWeightList.find((item) => item.code === code)?.weight!;
//       if (weight === undefined) return;
//       return {
//         code,
//         weight,
//       };
//     })
//     .filter((item) => item) as { code: string; weight: number }[];

//   let rightPartWeight = sortModeCode
//     .slice(Math.ceil(sortModeCode.length / 2))
//     .map((code) => {
//       let weight = codesWeightList.find((item) => item.code === code)?.weight!;
//       if (!weight) return;
//       return {
//         code,
//         weight: -weight,
//       };
//     })
//     .filter((item) => item) as { code: string; weight: number }[];

//   let middlePartWeight: { code: string; weight: number }[] = [];
//   if (sortModeCodeExistMiddle) {
//     let middleIndex = Math.floor(sortModeCode.length / 2);
//     let code = sortModeCode[middleIndex];
//     middlePartWeight = [
//       {
//         code,
//         weight: 0,
//       },
//     ];
//   }

//   let weights: { code: string; weight: number }[] = [
//     ...leftPartWeight,
//     ...middlePartWeight,
//     ...rightPartWeight,
//   ];

//   console.log(weights, "weights");

//   let resultSum = weights.reduce((acc, item) => acc + item.weight, 0);

//   let grades = getGradeListWithValue(mask.gradeList.map(g => g.rus), resultSum);

//   return {
//     name: mask.name,
//     grades,
//     weights: weights,
//     resultSum,
//   };
// };

// const getGradeListWithValue = (grades: string[], targetValue: number) => {
//   // Функция для определения цвета
//   const midIndex = Math.floor(grades.length / 2);

//   function getColor(value: number, index: number, midIndex: number) {
//     if (value === 0) {
//       return "#FFFF00"; // Жёлтый
//     }
//     const distanceFromMid = Math.abs(index - midIndex);
//     if (value > 0) {
//       return distanceFromMid <= 4 ? "#90EE90" : "#006400"; // Светло-зелёный или тёмно-зелёный
//     } else {
//       return distanceFromMid <= 4 ? "#FF4500" : "#8B0000"; // Ярко-красный или тёмно-красный
//     }
//   }

//   // Создать массив объектов с полями value, name и color
//   const modifiedArr = grades.map((name, index) => {
//     const step = 2; // Шаг увеличения/уменьшения
//     let value;
//     if (index === midIndex) {
//       value = 0; // Средний элемент равен 0
//     } else if (index < midIndex) {
//       value = (midIndex - index) * step; // Слева от середины
//     } else {
//       value = -(index - midIndex) * step; // Справа от середины
//     }
//     const color = getColor(value, index, midIndex); // Определяем цвет
//     return { value, name, color };
//   });

//   function addMatchFlagWithinRange(
//     arr: { value: number; name: string; color: string }[],
//     targetValue: number
//   ) {
//     // [3,2,1, 0 ,-1,-2,-3,-4]
//     let isPositiveTargetValue = targetValue >= 0;

//     // let elem;

//     // по возрастанию
//     let positiveArr = arr
//       .filter((a) => a.value >= 0)
//       .sort((a, b) => a.value - b.value);
//     // по убыванию
//     let negativeArr = arr
//       .filter((a) => a.value <= 0)
//       .sort((a, b) => b.value - a.value);

//     let aroundList = isPositiveTargetValue ? positiveArr : negativeArr;

//     let findedItem: { value: number; name: string; color: string } | undefined;

//     // console.log(aroundList, "aroundList");

//     aroundList.forEach((e, i) => {
//       if (findedItem) return;

//       let itemValue = e.value;
//       let nextItem = aroundList[i + 1];

//       if (!nextItem) {
//         findedItem = e;
//         return;
//       }

//       let itemValueAbs = Math.abs(itemValue);
//       let nextItemValueAbs = Math.abs(nextItem.value);
//       let targetValueAbs = Math.abs(targetValue);

//       // текущий элемент меньше целевого значения
//       if (itemValueAbs <= targetValueAbs) {
//         // следующий элемент больше целевого значения
//         // пропускаем

//         if (nextItemValueAbs < targetValueAbs) {
//           return;
//         }
//         // нашли - ищем что ближе к целевому значению

//         let nextDiff = Math.abs(nextItemValueAbs - targetValueAbs);
//         let curDiff = Math.abs(itemValueAbs - targetValueAbs);

//         findedItem = nextDiff < curDiff ? nextItem : e;
//       }
//     });

//     // console.log(findedItem, "findedItem");

//     return findedItem?.value;
//   }

//   let val = addMatchFlagWithinRange(modifiedArr, targetValue);

//   let modifiedArrWithFlag = modifiedArr.map((item) => {
//     if (item.value === val) {
//       return { ...item, flag: true };
//     }
//     return item;
//   });

//   return modifiedArrWithFlag;
// };

const ResultMaskItem = ({
  name,
  grades,
  weights,
  resultSum,
}: ResultMaskItemType) => {
  return (
    <div className="flex flex-col gap-2 items-center">
      <div className="text-lg font-bold text-center">{name}</div>
      <div className="text-sm text-gray-500">{resultSum.toFixed(2)}</div>
      <div className="flex flex-row text-sm mb-2 gap-1">
        {/* {JSON.stringify(weights)} */}
        {weights.map((w: any) => {
          return (
            <div key={w.code}>
              {w.code} <span className="text-gray-500">{w.weight}</span>
            </div>
          );
        })}
      </div>
      <div className="w-max flex flex-col gap-1">
        {grades.map((g: any) => {
          return (
            <div className="flex flex-row items-center" key={g.name}>
              {g.flag && (
                <div className="text-error mr-2">
                  <Icon icon="maki:arrow" height={20} />
                </div>
              )}
              <div
                className="flex flex-1 border-2 border-gray-200 items-center gap-2 px-2  text-white text-shadow-lg text-sm py-1 rounded-md "
                style={{
                  backgroundColor: g.color,
                }}
              >
                <div className="text-black">{g.value}</div>
                <div className="">{g.name}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ResultCardMasks;
